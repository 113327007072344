<template>
  <div class="deals-list">
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col cols="12" md="3" sm="6">
              <b-form-group label="Поиск">
                <b-form-input
                  v-model="filters.search"
                  :disabled="isSearchDisabled"
                  debounce="500"
                  placeholder="Поиск..."
                />
              </b-form-group>
            </b-col>
            <b-col
              class="d-flex justify-content-around flex-wrap flex-lg-nowrap"
              cols="12"
              md="9"
              sm="6"
            >
              <b-form-group label="Дата договор, от">
                <b-input-group>
                  <b-form-input
                    v-model="filters.date_document_start__gte"
                    placeholder="ГГГГ-ММ-ДД"
                    type="text"
                  />
                  <b-input-group-append>
                    <b-datepicker
                      v-model="filters.date_document_start__gte"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"
                      button-only
                      reset-button
                      right
                      size="sm"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <b-form-group label="Дата договора, до">
                <b-input-group>
                  <b-form-input
                    v-model="filters.date_document_start__lte"
                    placeholder="ГГГГ-ММ-ДД"
                    type="text"
                  />
                  <b-input-group-append>
                    <b-datepicker
                      v-model="filters.date_document_start__lte"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"
                      button-only
                      reset-button
                      right
                      size="sm"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <b-form-group label="Дата окончания, от">
                <b-input-group>
                  <b-form-input
                    v-model="filters.date_document_end__gte"
                    placeholder="ГГГГ-ММ-ДД"
                    type="text"
                  />
                  <b-input-group-append>
                    <b-datepicker
                      v-model="filters.date_document_end__gte"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"
                      button-only
                      reset-button
                      right
                      size="sm"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <b-form-group label="Дата окончания, до">
                <b-input-group>
                  <b-form-input
                    v-model="filters.date_document_end__lte"
                    placeholder="ГГГГ-ММ-ДД"
                    type="text"
                  />
                  <b-input-group-append>
                    <b-datepicker
                      v-model="filters.date_document_end__lte"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"
                      button-only
                      reset-button
                      right
                      size="sm"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col md="12">
              <b-form-group>
                <b-form-radio-group
                  id="btn-radios-1"
                  v-model="filters.status"
                  :disabled="isLoading"
                  :options="statusChoices"
                  button-variant="outline-primary"
                  buttons
                  name="radios-btn-default"
                  text-field="display_name"
                  value-field="value"
                  class="flex-wrap"
                />
              </b-form-group>
            </b-col>
            <b-col class="d-flex justify-content-end align-items-center">
              <b-button @click="getDealsExcel"> Скачать</b-button>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col>
              <b-overlay :show="isLoading" rounded="sm">
                <b-table
                  :fields="fields"
                  :items="deals"
                  empty-text="Нет данных"
                  hover
                  responsive
                  show-empty
                  @row-clicked="openDeal"
                >
                  <template
                    v-for="field in fields"
                    #[`cell(${field.key})`]="{ item }"
                  >
                    <router-link
                      :key="field.key"
                      :to="`/deals/${item.id}`"
                      style="color: #6e6b7b"
                    >
                      {{ item[field.key] }}
                    </router-link>
                  </template>
                  <template #head(document_number)="data">
                    <span @click="toggleOrdering('document_number')">
                      {{ data.label }} <b-icon-sort-alpha-down />
                    </span>
                  </template>
                  <template #head(initials)="data">
                    <span @click="toggleInitials()">
                      {{ data.label }} <b-icon-sort-alpha-down />
                    </span>
                  </template>
                  <template #head(date_document_start)="data">
                    <span @click="toggleOrdering('date_document_start')">
                      {{ data.label }} <b-icon-sort-alpha-down />
                    </span>
                  </template>
                  <template #head(date_document_end)="data">
                    <span @click="toggleOrdering('date_document_end')">
                      {{ data.label }} <b-icon-sort-alpha-down />
                    </span>
                  </template>
                  <template #head(closed_date)="data">
                    <span @click="toggleOrdering('closed_date')">
                      {{ data.label }} <b-icon-sort-alpha-down />
                    </span>
                  </template>
                </b-table>
              </b-overlay>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'DealsList',
  data() {
    return {
      month: '',
      selectedStatus: 1,
      statusChoices: [],
      deals: [],
      initialsOrdering: '',
      isLoading: false,
      isSearchDisabled: false,
      filters: {
        search: '',
        status: 1,
        ordering: '',
        date_document_start__gte: null,
        date_document_start__lte: null,
        date_document_end__gte: null,
        date_document_end__lte: null,
      },
      closedDateField: {
        key: 'closed_date',
        label: 'Дата закрытия',
      },
      fields: [
        {
          key: 'document_number',
          label: 'Договор',
        },
        {
          key: 'phone',
          label: 'Телефон',
        },
        {
          key: 'initials',
          label: 'Имя',
        },
        {
          key: 'value',
          label: 'Сумма, BYN',
        },
        {
          key: 'rate',
          label: 'Ставка',
          formatter: (value) => {
            return `${parseFloat(value) * 100}%`
          },
        },
        {
          key: 'date_document_start',
          label: 'Дата договора',
        },
        {
          key: 'date_document_end',
          label: 'Дата окончания',
        },
      ],
    }
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.getDeals()
        if (this.filters.status === 3)
          this.fields = [...this.fields, this.closedDateField]
        else
          this.fields = this.fields.filter(
            (field) => field.key !== 'closed_date'
          )
      },
    },
    initialsOrdering() {
      this.deals = this.deals.sort((a, b) =>
        a[this.initialsOrdering] > b[this.initialsOrdering] ? 1 : -1
      )
    },
  },
  mounted() {
    const formatter = new Intl.DateTimeFormat('ru', { month: 'long' })
    this.month = formatter.format(new Date())
  },
  created() {
    this.onCreated()
  },
  methods: {
    async getDeals() {
      this.isLoading = true
      const res = await this.$http.get('admin/deals/', {
        params: this.filters,
      })
      this.deals = res.data.results
      this.isLoading = false
    },
    async getDealsExcel() {
      const res = await this.$http.get('admin/deals-excel/', {
        params: this.filters,
        responseType: 'blob',
      })
      const url = URL.createObjectURL(
        new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
      )
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Сделки')
      document.body.appendChild(link)
      link.click()
      window.URL.revokeObjectURL(url)
      document.body.removeChild(link)
    },
    async getStatusOptions() {
      const res = await this.$http.options('admin/deals/')
      this.statusChoices = res.data.actions.POST.status.choices
    },
    openDeal(item) {
      this.$router.push({
        name: 'deal-view',
        params: { id: item.id },
      })
    },
    toggleOrdering(name) {
      if (name.includes(this.filters.ordering)) {
        this.filters.ordering = `-${name}`
      } else {
        this.filters.ordering = name
      }
    },
    toggleInitials() {
      if (this.initialsOrdering === 'initials') {
        this.initialsOrdering = '-initials'
      } else {
        this.initialsOrdering = 'initials'
      }
    },
    async onCreated() {
      this.isSearchDisabled = true
      await this.getDeals(this.selectedStatus)
      await this.getStatusOptions()
      this.isSearchDisabled = false
    },
  },
}
</script>
